import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["frame", "link"];

  reload() {
    if (this.hasFrameTarget) {
      this.frameTarget.reload();
    }
    if (this.hasLinkTarget) {
      this.linkTarget.click();
    }
  }
}
