import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    min: Number,
    max: Number,
  };

  static targets = ["value", "display"];

  connect() {
    this.value = Number(this.valueTarget.value);
    this._updateValue();
  }

  plus(event) {
    event.preventDefault();
    this.value = this.value + 1 > this.maxValue ? this.value : this.value + 1;
    this._updateValue();
  }

  minus(event) {
    event.preventDefault();
    this.value = this.value - 1 < this.minValue ? this.value : this.value - 1;
    this._updateValue();
  }

  _updateValue() {
    this.valueTarget.value = this.value;

    if (this.hasDisplayTarget) {
      this.displayTarget.innerText = this.value;
    }
  }
}
