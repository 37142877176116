import { Controller } from "stimulus";

const MIN_POLL_INTERVAL = 5000;

export default class extends Controller {
  static targets = ["link"];

  connect() {
    try {
      const pollInterval = Number(this.element.getAttribute("data-poll-interval")) || MIN_POLL_INTERVAL;

      const interval = setInterval(() => {
        try {
          if (this.hasLinkTarget) {
            this.linkTarget.click();
          }
        } catch (error) {
          console.log("Could not click linkTarget", error);
          clearInterval(interval);
        }
      }, Math.max(pollInterval, MIN_POLL_INTERVAL));
    } catch (error) {
      console.log("Could not initialize polling", error);
    }
  }
}
