import '../stylesheets/application.scss'

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import "@hotwired/turbo-rails"
Turbo.session.drive = false

import "controllers"